<template>
  <div class="d-none d-md-flex align-items-center">
    <div
      @click="goTo(1)"
      :class="{ active: step == 1, passed: step > 1 }"
      class="steps"
    >
      <span class="number"
        ><span v-if="step == 1">1</span
        ><img v-else src="../assets/images/step-ok.svg" alt="ok icon"
      /></span>
      {{ $t('navSteps.personalInfo') }} <span class="line"></span>
    </div>
    <div
      @click="goTo(2)"
      :class="{ active: step == 2, passed: step > 2 }"
      class="steps"
    >
      <span class="number"
        ><span v-if="step <= 2">2</span
        ><img v-else src="../assets/images/step-ok.svg" alt="ok icon"
      /></span>
      {{ $t('navSteps.workingExp') }} <span class="line"></span>
    </div>
    <div
      @click="goTo(3)"
      :class="{ active: step == 3, passed: step > 3 }"
      class="steps"
    >
      <span class="number"
        ><span v-if="step <= 3">3</span
        ><img v-else src="../assets/images/step-ok.svg" alt="ok icon"
      /></span>
      {{ $t('navSteps.educationAndSkills') }} <span class="line"></span>
    </div>
    <div
      @click="goTo(4)"
      :class="{ active: step == 4, passed: step > 4 }"
      class="steps"
    >
      <span class="number"
        ><span v-if="step <= 4">4</span
        ><img v-else src="../assets/images/step-ok.svg" alt="ok icon"
      /></span>
      {{ $t('navSteps.otherInfo') }} <span class="line"></span>
    </div>
    <div
      @click="goTo(5)"
      :class="{ active: step == 5, passed: step > 5 }"
      class="steps"
    >
      <span class="number"
        ><span v-if="step <= 5">5</span
        ><img v-else src="../assets/images/step-ok.svg" alt="ok icon"
      /></span>
      {{ $t('navSteps.summary') }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    step() {
      return this.$store.state.step;
    },
  },
  methods: {
    goTo(toStep) {
      if (this.step > toStep) {
        let step = toStep;
        this.$store.dispatch("step", { step });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #b4b8be;
  font-size: 0.8rem;
  width: min-content;

  @media (max-width: 991.98px) {
    display: none;
    &.active {
      display: flex;
    }
  }

  @media (max-width: 1199.98px) {
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999999px;
    border: 2px solid #e9ecf0;
    width: 32px;
    height: 32px;
    margin-right: 9px;
    font-size: 16px;
    flex-shrink: 0;
    @media (max-width: 1199.98px) {
      margin-right: 0;
      margin-bottom: 6px;
    }
  }

  .line {
    margin: 0 12px;
    width: 20px;
    height: 2px;
    background-color: #e9ecf0;
    @media (max-width: 1199.98px) {
      display: none;
    }
  }

  &.active {
    color: #279857;
    .number {
      border: 2px solid #279857;
    }
    .line {
      background-color: #279857;
    }
  }

  &.passed {
    cursor: pointer;
    color: #279857;
    .number {
      background-color: #279857;
      border: 2px solid #279857;
    }
    .line {
      background-color: #279857;
    }
  }
}
</style>
