<template>
  <div>
    <div class="steps-title mb-5">
        <h1 v-text="$t('work_history.title')" />
        <span class="line"></span>
        <p class="text" v-text="$t('work_history.text')" />
    </div>
    <draggable v-model="workHistory" @end="drag">
        <div v-for="(work, i) in workHistory" :key="i" class="accordion">
            <div class="box-label">
                <h2 class="d-flex align-items-center">
                    <img class="mr-3" src="../assets/images/lines.svg" alt="lines">
                    <span v-if="work.jobTitle">{{work.jobTitle}}</span><span v-else v-text="$t('work_history.jobTitle')" />
                </h2>
                <div class="d-flex">
                    <button class="action-btn mr-3" @click="deleteJob(i)">
                        <img src="../assets/images/bin.svg" alt="bin icon">
                    </button>
                    <button class="action-btn" v-b-toggle="'collapse-' + i" @click="toggle">
                        <img class="arrow" src="../assets/images/arrow.svg" alt="arrow icon">
                    </button>
                </div>
            </div>
            <b-collapse visible :id="'collapse-' + i">
                <b-card class="no-border">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="text-left">
                                <!-- <p>Job Title: {{work.jobTitle}}</p> -->
                                <label for="jobTitle" v-text="$t('work_history.jobTitle')" />
                                <br>
                                <input type="text" class="w-100 form-control" id="jobTitle" v-model="work.jobTitle">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="text-left">
                                <label for="employer" v-text="$t('work_history.employer')" />
                                <br>
                                <input type="text" class="w-100 form-control" id="employer" v-model="work.employer">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <Dates :dates="work.date" :checked="work.date.present" @update-date="updateJobDate(i, ...arguments)" />
                            <span>
                                <input type="checkbox" id="checkbox" :checked="work.date.present" @click="work.date.present = !work.date.present" />
                                <label class="checkbox-label" for="checkbox" v-text="$t('work_history.currentWork')" />
                            </span>
                        </div>
                        <div class="col-md-12">
                            <div class="text-left">
                                <label for="workDescription" v-text="$t('work_history.workDescription')" />
                                <vue-editor name="workDescription" id="workDescription" v-model="work.description" :editorToolbar="customToolbar"></vue-editor>
                            </div>                        
                        </div>
                    </div>
                </b-card>
            </b-collapse>
        </div>
    </draggable>
    <div class="text-left">
        <button class="add-job-btn" @click="addNewJob">
            <img src="../assets/images/red-cross.svg" alt="red plus icon">
            <span v-if="workHistory.length != 0" v-text="$t('work_history.addAnotherJob')" />
            <span v-else v-text="$t('work_history.addJob')" />
        </button>
    </div>
    <br>
    <div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-between mt-5">
        <button class="go-back" @click="step(1)" v-text="$t('work_history.goBack')" />
        <button class="custom-btn mb-3 mb-md-0" @click="step(3)" v-text="$t('work_history.button')" />
    </div>
  </div>
</template>

<script>
import Dates from '../components/Dates.vue'
import draggable from 'vuedraggable'
import { VueEditor } from "vue2-editor";

export default {
    data() {
        return {
            workHistory: [],
            divId: 2,
            customToolbar: [
                ["bold", "italic", "underline"],
                [{list: 'bullet'}, {list: 'order'}]
            ],            
        }
    },
    components: {
        draggable,
        VueEditor,
        Dates
    },
    methods: {
        updateJobDate(id, payload) {
            this.workHistory[id].date.from = payload[0]
            this.workHistory[id].date.to = payload[1]
        },
        addNewJob() {
            this.workHistory.push({
                'jobTitle': '',
                'employer': '',
                'date': {
                    'from': new Date(),
                    'to': new Date(),
                    'present': false
                },
                'description': '',
            })
        },
        deleteJob(i) {
            this.workHistory.splice(i, 1);
        },
        async step(step) {
            let workHistory = this.workHistory;
            await this.$store.dispatch('saveWorkHistory', {workHistory});
            this.$store.dispatch('step', {step});
        },
        toggle: function( event ) {
          event.target.classList.toggle('is-open')
        },
        async drag() {
            let workHistory = this.workHistory;
            await this.$store.dispatch('saveWorkHistory', {workHistory});
        },
    },
    watch: {
        present: {
            handler(val) {
                localStorage.setItem('present', val)
            }, deep: true
        },
        workHistory: {
            handler(val){
                // Saving data to localStorage
                localStorage.setItem('workHistory', JSON.stringify(val))
                let workHistory = this.workHistory;
                this.$store.dispatch('saveWorkHistory', {workHistory});
            },
            deep: true
        },
    },
    mounted() {
        // Retrieving data from localStorage
        if (JSON.parse(localStorage.getItem('workHistory')) != null && JSON.parse(localStorage.getItem('workHistory')).length != 0) {
            this.workHistory = JSON.parse(localStorage.getItem('workHistory'));
        } else {
            this.addNewJob()
        }
        if (JSON.parse(localStorage.getItem('present')) === null) {
            this.present = false
        }
    },
    filters: {
        toYear(val) {
            return val ? val.toLocaleDateString("en-GB", {year: "numeric", month: "2-digit"}) : null;
        },
    },
}
</script>

<style lang="scss" scoped>

.checkbox-label {
    position: relative;
    top: -2px;
    margin-left: 4px;
    font-size: 12px;
}

.add-job-btn {
    display: flex;
    font-weight: bold;
    border: 0;
    background-color: transparent;
    img {
        position: relative;
        top: -1px;
        margin-right: 8px;
    }
}

</style>

