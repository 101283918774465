export const templates = [
    {
        name: 'Template01',
        fr: {
            nickname: 'Europass',
        },
        it: {
            nickname: 'Europass',
        },
        thumb: 'europass-cv.png'
    },
    {
        name: 'Template02',
        fr: {
            nickname: 'Succès',
        },
        it: {
            nickname: 'Successo',
        },
        thumb: 'cv-template-1.png'
    },
    {
        name: 'Template04',        
        fr: {
            nickname: 'Bourreau de travail',
        },
        it: {
            nickname: 'Stacanovista',
        },
        thumb: 'cv-template-2.png'
    },
    {
        name: 'Template10',
        fr: {
            nickname: 'Intelligence',
        },
        it: {
            nickname: 'Go Intelegente',
        },
        thumb: 'cv-template-3.png'
    },
    {
        name: 'Template06',        
        fr: {
            nickname: 'Maître',
        },
        it: {
            nickname: 'Maestro',
        },
        thumb: 'cv-template-4.png'
    },
    {
        name: 'Template03',        
        fr: {
            nickname: 'Titan',
        },
        it: {
            nickname: 'Titano',
        },
        thumb: 'cv-template-5.png'
    },
    {
        name: 'Template08',        
        fr: {
            nickname: 'Infatigable',
        },
        it: {
            nickname: 'Instancabile',
        },
        thumb: 'cv-template-6.png'
    },
    {
        name: 'Template13',        
        fr: {
            nickname: 'Pratique',
        },
        it: {
            nickname: 'Pratico',
        },
        thumb: 'cv-template-7.png'
    },
    {
        name: 'Template11',        
        fr: {
            nickname: 'Soyez Malin',
        },
        it: {
            nickname: 'Fatti furbo',
        },
        thumb: 'cv-template-8.png'
    },
    {
        name: 'Template05',        
        fr: {
            nickname: 'Professionnel',
        },
        it: {
            nickname: 'Professionista',
        },
        thumb: 'cv-template-9.png'
    },
    {
        name: 'Template09',        
        fr: {
            nickname: 'Empereur',
        },
        it: {
            nickname: 'Imperatore',
        },
        thumb: 'cv-template-10.png'
    },
    {
        name: 'Template07',        
        fr: {
            nickname: 'Roi',
        },
        it: {
            nickname: 'Re',
        },
        thumb: 'cv-template-11.png'
    },
    {
        name: 'Template12',        
        fr: {
            nickname: 'Curieux',
        },
        it: {
            nickname: 'Curioso',
        },
        thumb: 'cv-template-12.png'
    },
    {
        name: 'Template14',        
        fr: {
            nickname: 'Combattant',
        },
        it: {
            nickname: 'Combattente',
        },
        thumb: 'cv-template-13.png'
    },
]